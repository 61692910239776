import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndividualModel } from 'common-services';
import { MomentUtcDateTimeAdapter } from './adapters/moment-utc-date-time-adapter.adapter';
import { MomentUtcDateAdapter } from './adapters/moment-utc-date.adapter';
import { TranslatedPaginator } from './material/translated-paginator';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function getUserLanguage() {
  let language = 'fr-FR';
  const individual: IndividualModel = JSON.parse(localStorage.getItem('individual'));
  language = individual?.languageBU?.code ?? language;
  return language;
}

const modules = [
  BrowserAnimationsModule,
  CdkTableModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSliderModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  OverlayModule
];

const globalConfiguration = [
  {
    provide: MAT_DATE_LOCALE,
    useFactory: getUserLanguage
  },
  {
    provide: LOCALE_ID,
    useFactory: getUserLanguage
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { float: 'auto' }
  },
  {
    provide: MatPaginatorIntl,
    useClass: TranslatedPaginator
  },
  {
    provide: DateAdapter,
    useClass: MomentUtcDateAdapter
  },
  {
    provide: NgxMatDateAdapter,
    useClass: MomentUtcDateTimeAdapter
  }
];

@NgModule({
  declarations: [],
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ],
  providers: [
    ...globalConfiguration
  ]
})
export class AngularMaterialsModule { }
