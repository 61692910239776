import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from 'app/generic-components/confirm-dialog/confirm-dialog.component';
import { GenericComponentsModule } from 'app/generic-components/generic-components.module';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { AngularMaterialsModule } from 'app/shared/angular-materials.module';
import { PositionModule } from '../position/position.module';
import { ActionFollowUpComponent } from './action-follow-up/action-follow-up.component';
import { ActionsCandidateComponent } from './actions-candidate/actions-candidate.component';
import { CandidateSocialNetworksComponent } from './candidate-social-networks/candidate-social-networks.component';
import { SendMailComponent } from './send-mail/send-mail.component';
import { GeolocateCandidateComponent } from './geolocate-candidate/geolocate-candidate.component';
import { WebSpeechModule } from 'web-speech';
import { FileUploaderModule } from 'file-uploader';

// Ajoutez vos components ici
const components = [
  CandidateSocialNetworksComponent,
  ActionsCandidateComponent,
  SendMailComponent,
  ActionFollowUpComponent,
  GeolocateCandidateComponent
];

// Ajoutez vos components ouverts par dialog ici
const dialogComponents = [
  SendMailComponent,
  ActionFollowUpComponent,
  CandidateContainerComponent,
  ConfirmDialogComponent
];

// Ajoutez vos modules ici
const modules = [
  GenericComponentsModule,
  AngularMaterialsModule,
  PositionModule,
  WebSpeechModule,
  FileUploaderModule
];

@NgModule({
  entryComponents: [
    ...dialogComponents
  ],
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class CandidateModule { }
