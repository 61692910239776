import { ElementRef, Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Dialogs } from '../enums/dialogs-enum';

@Injectable({
  providedIn: 'root'
})
export class DialogHelper {
  private readonly customModalboxClass = 'custom-modalbox';

  private readonly addCandidateDialogConfig: MatDialogConfig;
  private readonly candidateDialogConfig: MatDialogConfig;
  private readonly attachmentDialogConfig: MatDialogConfig;
  private readonly actionsCandidateDialogConfig: MatDialogConfig;
  private readonly savedRequestDialogConfig: MatDialogConfig;
  private readonly sendMailDialogConfig: MatDialogConfig;
  private readonly searchPositionDialogConfig: MatDialogConfig;
  private readonly confirmDialogConfig: MatDialogConfig;
  private readonly geolocateCandidatesDialogConfig: MatDialogConfig;

  constructor(
  ) {
    this.addCandidateDialogConfig = {
      id: 'addCandidateForm',
      width: '50%',
      height: '100%',
      autoFocus: false,
      panelClass: this.customModalboxClass,
      position: { top: '0', right: '0' },
      hasBackdrop: true
    };
    this.geolocateCandidatesDialogConfig = {
      id: 'geolocateCandidates',
      width: '50%',
      height: '100%',
      autoFocus: false,
      panelClass: this.customModalboxClass,
      position: { top: '0', right: '0' },
      hasBackdrop: false
    };
    this.candidateDialogConfig = {
      id: 'candidateForm',
      width: '50%',
      height: '100%',
      autoFocus: false,
      panelClass: this.customModalboxClass,
      position: { top: '0', right: '0' },
      hasBackdrop: false
    };
    this.attachmentDialogConfig = {
      id: 'attachmentContainer',
      width: '50%',
      height: '100%',
      autoFocus: false,
      panelClass: this.customModalboxClass,
      position: { top: '0', right: '0' },
      hasBackdrop: false
    };
    this.actionsCandidateDialogConfig = {
      id: 'actionsOnCandidateDialog',
      disableClose: true,
      autoFocus: true,
      width: '800px',
      height: '650px'
    };
    this.savedRequestDialogConfig = {
      id: 'savedRequestDialog',
      disableClose: true,
      autoFocus: true,
      width: '700px'
    };
    this.sendMailDialogConfig = {
      id: 'sendMailDialog',
      disableClose: true,
      width: '800px',
      height: '95%'
    };
    this.searchPositionDialogConfig = {
      id: 'searchPositionDialog',
      disableClose: true,
      autoFocus: true,
      width: '870px',
      height: '616px'
    };
    this.confirmDialogConfig = {
      id: 'confirmDialog',
      maxWidth: '400px'
    };
  }

  /**
   * Récupère la position d'un élément dans la page
   * @param element Elément de la page
   */
  public getPosition(element: Element) {
    if (!element) {
      return undefined;
    }
    const rect = element.getBoundingClientRect();
    if (!rect) {
      return undefined;
    }
    return {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      height: rect.height,
      x: (rect.x) ? rect.x : rect.left,
      y: (rect.y) ? rect.y : rect.top
    };
  }

  /**
   * Vérifie si la position de clic était sur la surface de l’élément
   * @param clickPosition Position du clic
   * @param elementSurface Surface à vérifier
   */
  public elementContainsPosition(clickPosition, elementSurface): boolean {
    if (!clickPosition || !elementSurface) {
      return false;
    }
    return elementSurface.x <= clickPosition.x && clickPosition.x <= elementSurface.x + elementSurface.width &&
      elementSurface.y <= clickPosition.y && clickPosition.y <= elementSurface.y + elementSurface.height;
  }

  /**
   * Récupère la configuration d'un dialog
   * @param dialog Dialog
   */
  public getDialogConfig(dialog: Dialogs): MatDialogConfig {
    switch (dialog) {
      case Dialogs.GeolocateCandidates:
        return this.geolocateCandidatesDialogConfig;
      case Dialogs.AddCandidateForm:
        return this.addCandidateDialogConfig;
      case Dialogs.CandidateForm:
        return this.candidateDialogConfig;
      case Dialogs.Attachment:
        return this.attachmentDialogConfig;
      case Dialogs.ActionsCandidate:
        return this.actionsCandidateDialogConfig;
      case Dialogs.SavedRequest:
        return this.savedRequestDialogConfig;
      case Dialogs.SendMail:
        return this.sendMailDialogConfig;
      case Dialogs.SearchPosition:
        return this.searchPositionDialogConfig;
      case Dialogs.ConfirmDialog:
        return this.confirmDialogConfig;
      default:
        return undefined;
    }
  }

  /**
  * Récupère l'identification d'un dialog
  * @param dialog Dialog
  */
  public getDialogIdentity(dialog: Dialogs | string): string {
    let id: string;
    if (typeof dialog !== 'string') {
      const dialogConfig = this.getDialogConfig(dialog);
      if (!dialogConfig) {
        return undefined;
      }
      id = dialogConfig.id;
    } else {
      id = dialog;
    }
    return id;
  }

  /**
  * Récupère la référence d'element HTML du dialogue
  * @param dialogRef Ref du dialog
  */
  public getContainerInstanceRef<T>(dialogRef: MatDialogRef<T>): ElementRef {
    const htmlElement = dialogRef['_containerInstance'];
    if (htmlElement) {
      return htmlElement['_elementRef'];
    }
    return undefined;
  }
}
