export enum Dialogs {
  CandidateForm = 1,
  Attachment = 2,
  ActionsCandidate = 3,
  SavedRequest = 4,
  SendMail = 5,
  SearchPosition = 6,
  ConfirmDialog = 7,
  AddCandidateForm = 8,
  GeolocateCandidates = 9
}
